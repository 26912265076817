import React, { memo } from 'react';
import styled from 'styled-components';
import each from 'lodash/each';
import { branch, mapProps, renderComponent } from 'recompose';
import { I } from '../../common/globals';
import {
	chatHasExternalCollaboration,
	internalChatCollabotionHotkey,
	isGroupChatCollaborate
} from '../../common/v5/helpers';
import {
	FooterCheckboxes,
	EditorFootnotes
} from './Footers';
import { CtrlKnowledgeBasePopup } from './KnowledgeBasePopup';
import
{
	default as ErrandTranslation,
	ErrandTranslationFrom
} from './ErrandTranslation';
import {
	composeWithDisplayName,
	withUnmountWhenHidden
} from '../../reactcomponents/hocs';
import {
	CtrlAssistDropdown
} from '../../reactcomponents/Dropdown';
import {
	// RPLY_COLLABORATE,
	RPLY_COMMENT,
	RPLY_GRP_CHAT_COL,
	CHAT_REPLY_TABS_ATTACHMENT,
	CHAT_RPLY_TAB_COLLABORATION,
	ERRAND_HOTKEYS,
	ST_ANSWERED,
	TOGGLE_COLLAB,
	USED_FOR_WHATSAPP_TEMPLATE
} from '../../common/v5/constants';
import { WrapIcon, disabledAnsweredOptions, deadChatReplyOptions } from '../../views/v5/errand'
import { ReplyToolbarCtnr } from '../../containers/toolbar';
import classNames from 'classnames';
import { getHotkeyChar } from '../../common/v5/helpers'
import { OneTab, OneTabWithTooltip} from '../../components/v5/ManualErrand';
import { AddAgentCtnr } from '../../containers/chat';
import OneReplyNav from '../../reactcomponents/OneReplyNav';
import { HotkeyLabel } from '../../reactcomponents/Hotkeys';
import { Spinner } from '../../reactcomponents/Spinner';
import ToolbarButtonIcon from '../../reactcomponents/ToolbarButtonIcon';
import { CtrlRewriteAnswerPopup } from './rewriteAnswerBase';
//chat reply form options
const chatReplyFormTabOptions = [
	{id: CHAT_REPLY_TABS_ATTACHMENT, label: I("Attachments"), icon: "icon-attachment"},
	{id: CHAT_RPLY_TAB_COLLABORATION, label: I("Collaboration"), icon: "icon-recipients"}
	// VIEW_REPLY_PANEL.Recipient,
	// VIEW_REPLY_PANEL.KnowledgeBase
	// Subject:		{id: 4, label: I("Subject")}
];

const TabOptions = ({ children }) => (
	<div className="tabs-option tab-selections">
		{children}
	</div>
)

const TabOptionsExtension = ({ children, hide }) => (
	<div className={classNames("tabs-option extending-option", {"collapse": hide})}>
		{children}
	</div>
)


export class ChatOptions extends React.Component {
	constructor(props){
		super(props);
		this.handleToggleKnowledgeBase = this.handleToggleKnowledgeBase.bind(this);
		this.handleToggleTemplate = this.handleToggleTemplate.bind(this);
		this.onAppendTemplate = this.onAppendTemplate.bind(this);
		this.handleSpellcheck = this.handleSpellcheck.bind(this);
		this.handleSelectToLang = this.handleSelectToLang.bind(this);
		this.handleSelectFromLang = this.handleSelectFromLang.bind(this);
		this.handleToggleShortcuts = this.handleToggleShortcuts.bind(this);
		this.handleSelectShortcuts = this.handleSelectShortcuts.bind(this);
		this.state = {
			openTemplate: false,
			openShortcuts: false,
			hideNav:false
			, openQuickReply: false
		}
	}

	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize() {
		//TODO(mujib): use Dimension component
		let currentHideNav = (window.innerWidth <= 1300);
		if (currentHideNav !== this.state.hideNav) {
			this.setState({hideNav: currentHideNav});
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}
	handleToggleKnowledgeBase(){
		if(cflag.IsActive("2024-02-20.CEN-1713.new-knowledgebase-gui") && cflag.IsActive("2024-05-23.CEN-2090.new-errand-knowledgebase-gui")){
			if(this.props.onToggleKBPanel){
				this.props.onToggleKBPanel(true);
			}
		} else {
			this.props.onToggleKnowledgeBase(!this.props.openKnowledgeBase);
		}
	}
	handleToggleTemplate(){
		this.setState({openTemplate: !this.state.openTemplate});
	}
	handleToggleQuickReply =()=>{
		this.setState({openQuickReply: !this.state.openQuickReply});
	}
	onAppendTemplate(id, ob){
		const editor = CKEDITOR.instances["chatEditor"];
			if(editor){
				let currentAns = editor.getData();
				let appended = currentAns;
				let erData = this.props.chat.errand.data;
				if(erData.sourceId !== Workflow.Errand.SERVICE_WHATSAPP){
					appended += ob.Content;
				}else{
					if( ob.UsedFor === USED_FOR_WHATSAPP_TEMPLATE ){
						this.props.onShowTemplateCustom(this.props.showWATemplatePopup, ob.Content, ob.Id);
					} else {
						if(ob.IsInteractive){
							appended = ob.Content;
							editor.setReadOnly(true);
							this.props.onTemplateChange(ob.Id, erData.id);
						} else {
							appended = ob.Content;
						}
					}
				}
				editor.setData(appended);
			}
		this.setState({openTemplate: false});
	}
	onInsertQuickReply =(id, ob)=>{
		const editor = CKEDITOR.instances["chatEditor"];
			if(editor){
				let currentAns = editor.getData();
				let appended = currentAns;
				let erData = this.props.chat.errand.data;
				if(erData.sourceId === Workflow.Errand.SERVICE_FACEBOOK){
					appended = ob.Content;
					editor.setReadOnly(true);
					this.props.onInsertQuickReply(ob.Id, appended, erData.id);
				}
				editor.setData(appended);
			}
		this.setState({openQuickReply: false});
	}
	handleSpellcheck(){
		CKEDITOR.instances["chatEditor"].execCommand('spellchecker');
	}
	handleSelectToLang(lang){
		const p = this.props;
		let sessionId = p.chat.sessionId;
		this.props.onSelectedToLang(sessionId, {to: lang});
	}
	handleSelectFromLang(lang){
		const p = this.props;
		let sessionId = p.chat.sessionId;
		this.props.onSelectedFromLang(sessionId, {from: lang});
	}
	handleToggleShortcuts(){
		this.setState({
			openShortcuts: !this.state.openShortcuts
		});
	}
	handleSelectShortcuts(id, obj){
		const editor = CKEDITOR.instances["chatEditor"];
			if(editor){
				let currentAns = editor.getData();
				let appended = currentAns + obj.Answer;
				editor.setData(appended);
				if(obj.Attachments) {
					this.props.onUploadChatAttachments(this.props.chat, obj.Attachments);
				}
			}
		this.setState({openShortcuts: false});
	}
	isChanFacebook = (chat) => {
		let erData = chat.errand.data;
		if(erData.sourceId === Workflow.Errand.SERVICE_FACEBOOK){
			return true
		}
		return false
	}
	//for each tag
	//TODO: maybe save to preference
	handleSelectedTab = (id) => {
		let p = this.props
		let selectedChatReplyTab = p.selectedChatReplyTab;
		p.onSelectChatReplyTab;

		if(selectedChatReplyTab === id) {
			p.onSelectChatReplyTab(false)
		} else if(id === CHAT_REPLY_TABS_ATTACHMENT) {
			p.onSelectChatReplyTab(id)
		}else if(id === CHAT_RPLY_TAB_COLLABORATION) {
			this.props.onSelectCollaboration();
			p.onSelectChatReplyTab(id)
		}
	}
	render(){
		const {
			libraryShortcuts
			, templates
			, quickReplies
			, canTranslate
			, currentState
			, toLang
			, fromLang
			, onAttachmentDelete
			, options
			, isOwner
			, joined
			, selectedChatReplyTab
			, onSelectChatReplyTab
			, onToggleAttachmentPanel
			, ...p
		} = this.props;
		const {
			hideNav
		} = this.state
		let busy, disabled;
		each(options, v => {
			let k = v.key;
			if (isGroupChatCollaborate(k)) {
				busy = !!v.busy;
				disabled = !!v.disabled;
				if (currentState === ST_ANSWERED) {
					disabled = disabledAnsweredOptions[k];
				} else if (p.chat) {
					if(p.chat.dead){
						disabled = deadChatReplyOptions[k];
					}
				}
				return false;
			}
		});

		let newChatReplyTabs = <div className="tabs-option-container">
			{/*upper tab section */}
			<TabOptionsExtension hide={!selectedChatReplyTab}>
				{selectedChatReplyTab === CHAT_REPLY_TABS_ATTACHMENT &&
				<EditorFootnotes
					archiveImgs={p.archiveImgs}
					attachFile={p.attachFile}
					attachments={p.attachments}
					chat={p.chat}
					currentReply={p.currentReply}
					dispatch={p.dispatch}
					isUploading={p.isUploading}
					showAttachment={p.showAttachment}
					showAttachmentPanel={p.showAttachmentPanel}
					showArchiveDD={p.showArchiveDD}
					showUploadAttachmentBox={p.showUploadAttachmentBox}
					onAttachmentDelete={onAttachmentDelete}
					onFileupload={p.onFileupload}
					onShowArchiveAttach={p.showArchiveAttch}
					onUploadArchive={p.onUploadArchive}
					canInsertIncomingAttachments={p.canInsertIncomingAttachments}
					selectAllQuestionFiles={p.selectAllQuestionFiles}
					onToggleAttachmentPanel={onToggleAttachmentPanel}
					onSelectAllCurrentAttachment={p.onSelectAllCurrentAttachment} />
				}
				{/*collab panel section */}
				{
				// (p.chat && (isOwner || joined)) && isGroupChatCollaborate(p.currentReply)
				(p.chat && (isOwner || joined)) && selectedChatReplyTab === CHAT_RPLY_TAB_COLLABORATION && p.currentReply !== RPLY_COMMENT
				&& <AddAgentCtnr //when on chat collab reply
						error={p.chat.addAgent.error}
						list={p.chat.addAgent.list}
						sessionId={p.chat.sessionId}
						agentIds={p.chat.AgentIds ? p.chat.AgentIds.concat(p.chat.InvitedAgentIds) : []}
						ownerId={p.chat.errand.data.agentId}
						agentPresence={this.props.agentStore}/>
				}
			</TabOptionsExtension>
			{/*lower tab section */}
			{p.currentReply === RPLY_COMMENT ?
			<TabOptions>
				{features["chat.attachment"] &&
					<OneTab
						// key={}
						active={selectedChatReplyTab === "chatAttachmentsTab"}
						id={"chatAttachmentsTab"}
						onClick={this.handleSelectedTab}
						data-qa-id={"reply-form-option chatAttachmentsTab"}
						text={I("Attach")}
						icon={"icon-attachment"}
					/>
				}
			</TabOptions>
			: <React.Fragment>
				{!hideNav &&
				<TabOptions>
					<WrapIcon icon="icon-knowledgebase" className="toggle-tabs knowledgebase">
						<ul className="reply-links">
							<li><CtrlKnowledgeBasePopup id="chatKnowledgeBase"
								open={this.props.openKnowledgeBase}
								onToggle={this.handleToggleKnowledgeBase}
								title={I("Knowledge base")} /></li>
						</ul>
					</WrapIcon>
					{
						libraryShortcuts.length > 0 &&
						<div className="reply-links-label">
							<WrapIcon icon="icon-keyboard" className="toggle-tabs template">
								<CtrlAssistDropdown
									open={this.state.openShortcuts}
									title={I("SHORTCUTS")}
									ddCustomClass={"chatToolbar library"}
									customClass={"chat-toolbar-list library-dd"}
									showToggleArrow={true}
									direction={"up"}
									id={"shortcuts"}
									data={libraryShortcuts}
									idFields={{id: "Id", value: "Name"}}
									onToggle={this.handleToggleShortcuts}
									onAppendItem={this.handleSelectShortcuts}
								/>
							</WrapIcon>
						</div>
					}
					{features["chat.attachment"] &&
						<OneTab
							// key={}
							active={selectedChatReplyTab === "chatAttachmentsTab"}
							id={"chatAttachmentsTab"}
							onClick={this.handleSelectedTab}
							data-qa-id={"reply-form-option chatAttachmentsTab"}
							text={I("Attach")}
							icon={"icon-attachment"}
						/>
					}
					<WrapIcon icon="icon-template" className="toggle-tabs template">
						<CtrlAssistDropdown open={this.state.openTemplate}
							title={I("Templates")}
							ddCustomClass={"chatToolbar answer-template"}
							customClass={"chat-toolbar-list"}
							id={"templates"}
							direction={"up"}
							data={templates} idFields={{id: "Id", value: "Name", content: "Content"}}
							onAppendItem={this.onAppendTemplate} appendAction={true}
							onToggle={this.handleToggleTemplate}
						/>
					</WrapIcon>
					{features["facebook.quick.reply"] && this.isChanFacebook(p.chat) &&
						<WrapIcon icon="icon-template" className="toggle-tabs template">
							<CtrlAssistDropdown
								open={this.state.openQuickReply}
								title={I("Quick Reply")}
								ddCustomClass={"chatToolbar answer-template"}
								customClass={"chat-toolbar-list"}
								id={"quickreplies"}
								direction={"up"}
								data={quickReplies}
								idFields={{id: "Id", value: "Name", content: "Content"}}
								onAppendItem={this.onInsertQuickReply}
								appendAction={true}
								onToggle={this.handleToggleQuickReply}
							/>
						</WrapIcon>
					}
					<WrapIcon icon="icon-spellchecker" className="toggle-tabs spellcheck">
						<a href="#" onClick={this.handleSpellcheck} className="errand-translate">
							{I("Spell check")}
						</a>
					</WrapIcon>
					{/*collab */}
					<WrapIcon icon={"icon-recipients"} className={classNames("toggle-tabs collaboration", {"active": selectedChatReplyTab === CHAT_RPLY_TAB_COLLABORATION})}>
						<div className="one-reply-nav">
							<div onClick={() => this.handleSelectedTab(CHAT_RPLY_TAB_COLLABORATION)}
								// onClick={this.handleCollabTab}
								disabled={disabled}
								data-qa-id={"QA_tab_collaborate"}
							>
								<span>
									<HotkeyLabel
										id={RPLY_GRP_CHAT_COL}
										text={I('COLLABORATE')}
										hotkey={internalChatCollabotionHotkey()}
									/>
								</span>
								<Spinner hidden={!busy} />
							</div>
						</div>
					</WrapIcon>
					<WrapIcon hidden={!this.props.shouldShowChatTranslation} icon="icon-translate" className="toggle-tabs translate">
						<ErrandTranslationFrom
							text={I("Translation options")}
							className="chat-message-translate"
							fromLang={fromLang} onSelectedFromLang={this.handleSelectFromLang}
						/>
						<span style={{"paddingLeft":"3px","paddingRight":"3px"}}><div className="translateBetween">{I("to")}: </div></span>
						<ErrandTranslation
							text={I("Translation options")}
							className="chat-message-translate"
							toLang={toLang} onSelectedToLang={this.handleSelectToLang}
						/>
					</WrapIcon>
				</TabOptions>}

				{hideNav &&
				<TabOptions>
					<WrapIcon icon="icon-knowledgebase" className="toggle-tabs knowledgebase errandTooltip" onClick={this.handleToggleKnowledgeBase}>
						<ul className="reply-links">
							<li><CtrlKnowledgeBasePopup id="chatKnowledgeBase"
								open={this.props.openKnowledgeBase}
								onToggle={this.handleToggleKnowledgeBase}
								title="" />
							</li>

						</ul>
						<span className="tooltiptext">{I("Preview")}</span>
					</WrapIcon>
					{
						libraryShortcuts.length > 0 &&
						<div className="reply-links-label">
							<WrapIcon icon="icon-keyboard" className="toggle-tabs template">
								<CtrlAssistDropdown
									open={this.state.openShortcuts}
									title={I("SHORTCUTS")}
									ddCustomClass={"chatToolbar library"}
									customClass={"chat-toolbar-list library-dd"}
									showToggleArrow={true}
									direction={"up"}
									id={"shortcuts"}
									data={libraryShortcuts}
									idFields={{id: "Id", value: "Name"}}
									onToggle={this.handleToggleShortcuts}
									onAppendItem={this.handleSelectShortcuts}
								/>
							</WrapIcon>
						</div>
					}
					{features["chat.attachment"] &&
						<OneTabWithTooltip
							// key={}
							active={selectedChatReplyTab === "chatAttachmentsTab"}
							id={"chatAttachmentsTab"}
							onClick={this.handleSelectedTab}
							data-qa-id={"reply-form-option chatAttachmentsTab"}
							text={""}
							icon={"icon-attachment"}
							tooltiptext={I("Attach")}
						/>
					}
					<WrapIcon icon="icon-template" className="toggle-tabs template errandTooltip" onClick={this.handleToggleTemplate}>
						<CtrlAssistDropdown open={this.state.openTemplate}
							title={""}
							ddCustomClass={"chatToolbar answer-template"}
							customClass={"chat-toolbar-list"}
							id={"templates"}
							direction={"up"}
							data={templates} idFields={{id: "Id", value: "Name", content: "Content"}}
							onAppendItem={this.onAppendTemplate} appendAction={true}
							onToggle={this.handleToggleTemplate}
						/>
						<span className="tooltiptext">{I("Templates")}</span>
					</WrapIcon>
					<WrapIcon icon="icon-spellchecker" className="toggle-tabs spellcheck errandTooltip" onClick={this.handleSpellcheck}>
						<a href="#" onClick={this.handleSpellcheck} className="errand-translate">
							{""}
						</a>
						<span className="tooltiptext">{I("Spell check")}</span>
					</WrapIcon>
					{/*collab */}
					<WrapIcon icon={"icon-recipients"} className={classNames("toggle-tabs collaboration", {"active": selectedChatReplyTab === CHAT_RPLY_TAB_COLLABORATION}) + " errandTooltip"} onClick={() => this.handleSelectedTab(CHAT_RPLY_TAB_COLLABORATION)}>
						<div className="one-reply-nav">
							<div onClick={() => this.handleSelectedTab(CHAT_RPLY_TAB_COLLABORATION)}
								// onClick={this.handleCollabTab}
								disabled={disabled}
								data-qa-id={"QA_tab_collaborate"}
							>
								<span>
									<HotkeyLabel
										id={RPLY_GRP_CHAT_COL}
										text={""}
										hotkey={internalChatCollabotionHotkey()}
									/>
								</span>
								<Spinner hidden={!busy} />
							</div>
						</div>
						<span className="tooltiptext">{I("Collaborate")}</span>
					</WrapIcon>
					<WrapIcon icon="icon-translate" className="toggle-tabs translate">
						<ErrandTranslationFrom
							text={I("Translation options")}
							className="chat-message-translate"
							fromLang={fromLang} onSelectedFromLang={this.handleSelectFromLang}
						/>
						<span style={{"paddingLeft":"3px","paddingRight":"3px"}}><div className="translateBetween">{I("to")}:</div></span>
						<ErrandTranslation
							text={I("Translation")}
							className="chat-message-translate"
							toLang={toLang} onSelectedToLang={this.handleSelectToLang}
						/>
					</WrapIcon>
				</TabOptions>}
			</React.Fragment>
			}
		</div>;
		return(newChatReplyTabs);
	}
}

const ToolbarButton = ({ active, onClick }) => (
	<ToolbarButtonIcon active={active} onClick={onClick} />
)

const newUI = true

const styleWidth = { width: '25%' }

const FooterAttachmentBase = ({
	allowChatAttachment,
	attachmentSize,
	chat,
	currentReply,
	showUploadAttachmentBox,
	toggleUploadAttachment
}) => (
	<div style={styleWidth}>
		<FooterCheckboxes
			attachmentSize={attachmentSize}
			chat={chat}
			currentRoot={currentReply}
			showUploadAttachmentBox={showUploadAttachmentBox}
			toggleUploadAttachment={toggleUploadAttachment}
		/>
	</div>
)

const FooterAttachment = composeWithDisplayName(
	'FooterAttachment',
	mapProps(({ allowChatAttachment, currentReply, ...props }) => ({
		hidden: !allowChatAttachment || chatHasExternalCollaboration(currentReply),
		currentReply,
		...props
	})),
	withUnmountWhenHidden
)(FooterAttachmentBase)

const NotToolbarItems = composeWithDisplayName(
	'NotToolbarItems',
	memo,
	branch(() => newUI, renderComponent(ToolbarButton))
)(FooterAttachment)

const StyledToolbar = styled(ReplyToolbarCtnr)`
	&.chat-toolbar.reply-toolbar {
		flex-direction: row${newUI ? '-reverse' : ''};
		${newUI ? 'justify-content: flex-end;' : ''}
		.channel {
			flex-grow: 1;
			line-height: 3;
		}
		.toolbar {
			padding-right: ${newUI ? 'unset' : '40px'};
		}
	}
`
const ChatToolbar = ({
	attachmentSize,
	chat,
	className,
	collabOpts,
	currentReply,
	involved,
	onToggleEditorToolbar,
	options,
	replyAs,
	showReplyToolbar,
	showUploadAttachmentBox,
	toggleUploadAttachment,
	toolbarID,
}) => (
	<StyledToolbar
		className={classNames('chat-toolbar', className)}
		collabOpts={collabOpts}
		data-qa-id="chat-toolbar"
		hideToolbar={isGroupChatCollaborate(currentReply) || !showReplyToolbar}
		options={options}
		replyAs={replyAs}
		show={involved}
		showChannelOpt={newUI &&
			chatHasExternalCollaboration(currentReply) &&
			showReplyToolbar
		}
		toolbarID={toolbarID}
	>
		<NotToolbarItems
			active={showReplyToolbar}
			allowChatAttachment={features["chat.attachment"]}
			attachmentSize={attachmentSize}
			chat={chat}
			currentReply={currentReply}
			onClick={onToggleEditorToolbar}
			showUploadAttachmentBox={showUploadAttachmentBox}
			toggleUploadAttachment={toggleUploadAttachment}
		/>
	</StyledToolbar>
);

export default ChatToolbar;
